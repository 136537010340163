import React, { Component } from 'react';
// import Link from 'gatsby-link';
// import logo from '../../imgs/logo.png';
import Card from '../Card/Card';
import team from '../../../assets/team_members.json';

class Team extends Component {
  render() {
    return (
      <div id='team' className='container' style={{ paddingTop: 5 }}>
        <div className="columns is-multiline">
          {team.map((member, index) => {
            return (
              <div key={index} className="column is-one-third">
                <Card member={member} />
              </div>
            );
          })}
        </div>
      </div>
    );
  };
};

export default Team;
