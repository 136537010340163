import React, { Component } from "react";
import Helmet from "react-helmet";
import config from "../../data/SiteConfig";

import Layout from "../components/layout";
import SectionTitle from "../components/SectionTitle/SectionTitle";
import Content from "../components/Content/Content";
import Team from "../components/Team/Team";

import FullWidthImage2 from "../components/FullWidthImage2/FullWidthImage2";
// import img from "../imgs/american-public-power-association-419672-unsplash.jpg";
const img =
  "https://res.cloudinary.com/downtowndev/image/upload/q_auto,f_auto/american-public-power-association-419672-unsplash_c7cpaa.jpg";

class AboutPage extends Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <div className="about-container underNavbar">
          <Helmet
            htmlAttributes={{ lang: "en" }}
            title={`404 | ${config.siteTitle}`}
          />
          <FullWidthImage2 title={"404 Page Not Found"} image={img} />
          <Content className="page-content">
            <h2>Oops!</h2>
            The page you are looking for has been removed or relocated.
          </Content>
        </div>
      </Layout>
    );
  }
}

export default AboutPage;
